<template>
  <AuthLayout>
    <template #leftContent>
      <div class="intro-x w-full h-full">
        <img
          src="/icons/illustrations/onboarding-amico.svg"
          class="inline w-full h-full"
        >
      </div>
    </template>
    <template #rightContent>
      <h2
        class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
      >
        Sign In
      </h2>
      <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
        A few more clicks to sign in to your account.
      </div>
      <form
        method="POST"
        @submit.prevent="login"
      >
        <div class="intro-x mt-8">
          <label :class="{ 'has-error': hasError('email') }">
            <input
              v-model="form.email"
              class="intro-x login__input input input--lg border border-gray-300 block"
              placeholder="Email"
              type="text"
            >
            <span
              v-if="hasError('email')"
              class="pristine-error text-theme-6 mt-2"
            >
              {{ getError("email") }}
            </span>
          </label>
          <label :class="{ 'has-error': hasError('password') }">
            <input
              v-model="form.password"
              class="intro-x login__input input input--lg border border-gray-300 block mt-4"
              placeholder="Password"
              type="password"
            >
            <span
              v-if="hasError('password')"
              class="pristine-error text-theme-6 mt-2"
            >
              {{ getError("password") }}
            </span>
          </label>
        </div>
        <div
          class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
        >
          <div class="flex items-center mr-auto">
            <input
              id="remember-me"
              class="input border mr-2"
              type="checkbox"
            >
            <label
              class="cursor-pointer select-none"
              for="remember-me"
            >Remember me</label>
          </div>
          <router-link :to="{ name: 'Recovery' }">
            Forgot Password?
          </router-link>
        </div>
        <div
          class="intro-x flex flex-wrap space-y-3 xl:space-y-0 mt-5 xl:mt-8 text-center xl:text-left"
        >
          <base-button
            :loading="loading"
            type="submit"
            class="inline-block button button--lg w-full xl:w-32 text-white bg-theme-1 xl:mr-3 align-top"
          >
            Login
          </base-button>
        </div>
      </form>
    </template>
  </AuthLayout>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";

import "@lottiefiles/lottie-player";
import loginAnimation from "@/assets/animations/lottie/login-colored.json";

import AuthLayout from "../layouts/AuthLayout";

export default defineComponent({
  name: "Login",
  components: {AuthLayout },
  data() {
    return {
      errors: {},
      loading: false,
      form: {
        email: "",
        password: "",
        device_name: "windows 10"
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      const player = document.querySelector("lottie-player");
      player.load(loginAnimation);
    });
  },
  methods: {
    ...mapActions("auth", {
      signIn: "login"
    }),
    async login() {
      this.errors = {};
      this.loading = true;
      try {
        await this.signIn(this.form);
        this.loading = false;
        this.$router.push("/dashboard");
      } catch (e) {
        console.log(e);
        this.loading = false;
        this.errors = e.errors;
      }
    },
    hasError(field) {
      return this.errors[field];
    },
    getError(field) {
      return this.errors[field][0];
    }
  }
});
</script>
